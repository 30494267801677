// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

let ENV;
 if(process.env.NEXT_PUBLIC_ENVIRONMENT === "preview" || process.env.NEXT_PUBLIC_ENVIRONMENT === "development"){ENV =  "development"};
 if(process.env.NEXT_PUBLIC_ENVIRONMENT === "production"){ENV =  "production"};

Sentry.init({
  dsn: 'https://a8d9155589264186aa553a9beb10c1a8@o468354.ingest.sentry.io/6092356',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
