import React, { useState } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "@styles/theme";
import { MDXProvider } from "@mdx-js/react";
import mdxComponents from "@styles/mdxComponents";
import Navigation from "../components/navigation/Navigation";
import "styles/globals.css";
import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import "./../styles/nprogress.css"; //styles of nprogress
import styles from "@styles/styles";

// Binding events. => this should display a loading bar
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export default function MyApp(props: any) {
  const { Component, pageProps } = props;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>Aeco Docs</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      {/* <MDXProvider components={mdxComponents}> */}
      {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> */}
      {/* <Component {...pageProps} /> */}
      {/* </ThemeProvider> */}
      {/* </MDXProvider> */}
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
